.attribute-pill {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  background:  #e6e6e6;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  color: #222;
  height: 32px;
  max-width: 80px;
  max-width: 200px;
  overflow: hidden;
  padding: 6px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }
}
