@import "~@teamsnap/teamsnap-ui/src/css/config/themes/league";
.Checkbox--radio.Checkbox--inline {
  margin-right: $su-medium;
  padding-left: $su-medium;
}

.FieldGroup {

  :first-child.Checkbox--inline {
    padding-left: 0px;
  }

  &.error input {
    border-color: $cu-negative;
  }

}

.validation-error {
  color: $cu-negative;
}
