@import "~@teamsnap/teamsnap-ui/src/css/config/themes/league";
.date-picker {

  position: relative;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .calendar {
    cursor: pointer;
    position: absolute;
    height: $su-xlarge;
    width: $su-xlarge;
    line-height: $su-xlarge;
    text-align: center;
    right: 0;
    top: 0;
  }

}
