.organization-app .pill {
  padding: 4px 8px;
  border-radius: 8px;
  height: 16px;
  line-height: 16px;
  padding: 0 8px;
  font-family: "Open Sans", Helvetica, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 700;
  top: -8px;
  right: 4px;
  background-color: #13426e;
}

// This is gross, but this fixes as weird issue Damien was seeing that stems from
// and override in TeamSnap UI
// TODO: Remove me once we fix this in TeamSnap UI v4.
.organization-app #members .ButtonGroup {
  overflow: hidden !important;
}
