@import "~@teamsnap/teamsnap-ui/src/css/teamsnap-ui.scss";

body {
  height: 100vh;
}

#root {
  height: 100%;
}

.Toolbar-flyout {
  transform: translateX(-75%) !important;
}


.hub-full-width {
  width: 100%;
}

.page-content .page-content-header {
  margin-bottom: 16px;
}

.Toolbar-flyout {
  z-index: 999;
}

address {
  font-style: normal;
}

.button-list,
.action-list {
  a,
  .Button {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.pill {
  padding: 4px 8px;
  border-radius: 8px;
  height: 16px;
  line-height: 16px;
  padding: 0 8px;
  font-family: "Open Sans", Helvetica, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 700;
  top: -8px;
  right: 4px;
  background-color: #13426e;
}
