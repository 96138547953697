@import "~@teamsnap/teamsnap-ui/src/css/config/themes/league";
#programs {

  .program-card {

    .season-info {
      .season-info-has-active {
        
      }
      .season-info-divider {
        padding: 0 4px;
      }
      .season-info-active-season {

      }
    }
  }
}
