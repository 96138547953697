
@import "~@teamsnap/teamsnap-ui/src/css/config/themes/league";
/************/
/** Full Modal **/
/***********/

.full-modal {
  background: $ts-white;

  .full-modal-content {
    padding: 30px 0;

    // .modal-heading {
    //   margin-bottom: 28px;
    // }
  }

  .modal-btn {
      position: absolute;
      top: 30px;
      width: 60px;
      height: 60px;
      z-index: 1;
      text-align: center;
      cursor: pointer;
      color: $cu-info--light;
      font-size: 30px;
      line-height: 40px;
      border-radius: 100%;

      &:hover {
          color: $ts-black;
          background: $ts-grey;
      }

      &.modal-close-btn {
        right: 30px;

        .modal-esc-icon {
          width: 20px;
          height: auto;
        }

        .modal-esc {
          display: block;
          margin-top: -3px;
          font-size: 11px;
          line-height: 12px;
        }
      }
  }
}

.ReactModalPortal {
  // allows scrolling over modal content
  .ReactModal__Overlay {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
