@import "~@teamsnap/teamsnap-ui/src/css/config/themes/league";
#organization-create-editor-content {
  textarea {
    height: 200px;
  }

  .resendInvite {
    margin-top: 8px;
  }

  .accepted {
    margin-left: 5px;
    color: $ts-green;
    font-size: 17px;
  }
}
