@import "~@teamsnap/teamsnap-ui/src/css/config/themes/league";
%close {
  border: none;
  background: transparent;
  float: right;
  height: auto;
  padding: 0 0 $su-small $su-small;
  line-height: $tu-base-lineHeight;
  color: inherit;
}

// https://github.com/daneden/animate.css
// We should look for a more concise approach to animations
@keyframes fadeInDown {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

%feedback {
  top: 0;
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  border-radius: $border-radius-medium;
  line-height: $tu-base-lineHeight;
  padding: $su-medium $su-large;
  width: 85%;
  max-width: scaleGrid(40);
  box-shadow: $box-shadow-large;
  float: right;
  margin-right: $su-medium;
  position: fixed;
  z-index: 99999;
}

.Feedback--popup.Feedback {
  &--negative {
    @extend %feedback;
    background: $cu-foreground;
    border: 1px solid $cu-negative;
    .FeedbackClose {
      @extend %close;
    }
  }
  &--highlight {
    @extend %feedback;
    background: $cu-foreground;
    border: 1px solid $cu-highlight;
    .FeedbackClose {
      @extend %close;
    }
  }
  &--positive {
    @extend %feedback;
    background: $cu-foreground;
    border: 1px solid $cu-positive;
    .FeedbackClose {
      @extend %close;
    }
  }

  .Feedback-contents {
    margin-left: 24px;
  }
}

#content .Feedback--popup {
  top: 5px;
  text-align: left;
  padding-left: 47px;
}
